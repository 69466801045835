import { ContentfulLivePreview } from "@contentful/live-preview";

const subscriptions = [];

const displayFieldData = (domElement, entry, fieldId) => {
  if (!domElement) {
    console.error(`DOM element not found for field ID "${fieldId}".`);
    return;
  }
  domElement.textContent = entry.fields[fieldId];
};

const setupLivePreview = (domElement, entry, fieldId) => {
  const callback = (updatedData) => {
    if (updatedData.fields && updatedData.fields[fieldId]) {
      if (typeof updatedData.fields[fieldId] === "string") {
        domElement.textContent = updatedData.fields[fieldId];
        // Sync the text change with the data-text attribute if it exists
        // This is used for the glowing text.
        // TODO consider some way to define which attributes should be updated.
        if (domElement.hasAttribute("data-text")) {
          domElement.setAttribute("data-text", updatedData.fields[fieldId]);
        }
      }
    }
  };

  const unsubscribe = ContentfulLivePreview.subscribe({
    data: entry,
    locale: document.documentElement.lang,
    callback,
  });

  subscriptions.push(unsubscribe);
};

function initialisePreview() {
  const fields = document.querySelectorAll("[data-contentful-field-id]");
  if (fields.length < 1) {
    return;
  }

  if (!window.previewData) {
    return;
  }
  const { territory, ...config } = window.previewData;

  if (!territory) {
    return;
  }

  ContentfulLivePreview.init({
    ...config,
    locale: document.documentElement.lang,
  });

  fetch(`/preview/${territory}`)
    .then((response) => response.json())
    .then((response) => {
      const contentItems = response.items.reduce((acc, item) => {
        acc[item.sys.id] = item;
        return acc;
      }, {});

      fields.forEach((fieldElement) => {
        const entryElement = fieldElement.closest("[data-contentful-entry-id]");
        const entryId = entryElement.getAttribute("data-contentful-entry-id");
        if (!contentItems.hasOwnProperty(entryId)) {
          return;
        }

        const entry = contentItems[entryId];

        const fieldId = fieldElement.getAttribute("data-contentful-field-id");
        if (entry && fieldId) {
          displayFieldData(fieldElement, entry, fieldId);
          setupLivePreview(fieldElement, entry, fieldId);
        }
      });
    })
    .catch((err) => console.error(err));
}

document.addEventListener("DOMContentLoaded", initialisePreview);
